import React from "react";
import Layout from "../../../../components/Layout/layout";
import BlogDetailPage from "../../../../components/Blog-Detail/hireTaxi/index";
import SEO from "../../../../components/SEO/seo";
const BlogDetail = () => {
	return (
		<Layout>
			<SEO
				title="Restaurants, Hotel & Bars: The best way to hire a taxi in Hamburg"
				meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
				description=" Taxi is the best option whether you are at a hotel, bar, and restaurant. If you want to know how you can book the taxi at these places, you can read our blog."
			/>
			<BlogDetailPage />
		</Layout>
	);
};

export default BlogDetail;
